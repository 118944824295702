@font-face {
  font-family: "Metropolis";
  src: url("../fonts/metropolis-medium_500-webfont.woff2") format("woff2"),
    url("../fonts/metropolis-medium_500-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/metropolis_medium_italic_500-webfont.woff2") format("woff2"),
    url("../fonts/metropolis_medium_italic_500-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/metropolis_bold_700-webfont.woff2") format("woff2"),
    url("../fonts/metropolis_bold_700-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Tiempos Fine";
  src: url("../fonts/tiempos-fine-web-bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
